
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Borde.css';
import { useTranslation } from 'react-i18next'; 
import Document from './images/green/Document2.png';
 
const Borde = ({  setIsLoading }) => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices1, setFilteredServices1] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isDesktopFilterVisible, setDesktopFilterVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [additionalServices1, setAdditionalServices1] = useState([]);

  
  
  useEffect(() => {
    const loadData1 = async () => {
      try {
        const response1 = await fetch(process.env.REACT_APP_API_URL);
        const data1 = await response1.json();

        if (Array.isArray(data1)) {
          setAdditionalServices1(data1);
        } else {
          console.error("Data1 is not in the expected format (array)");
          setAdditionalServices1([]);
        }
      } catch (error) {
        console.error("Error loading additional services data:", error);
      }
    };

    loadData1();
  }, []);

 
  


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };





  useEffect(() => {
    if (debouncedQuery && Array.isArray(additionalServices1)) {
      const filtered = additionalServices1.filter(service => {
        const title = service.title?.toLowerCase() || '';
        const summary = service.summary?.toLowerCase() || '';
        const includedServices = service.included_services?.toLowerCase() || '';


        return (
          (title.includes(debouncedQuery.toLowerCase()) ||
            summary.includes(debouncedQuery.toLowerCase()) ||
            includedServices.includes(debouncedQuery.toLowerCase()))
        );
      });
      setFilteredServices1(filtered);
    } else {
      setFilteredServices1([]);
    }
  }, [debouncedQuery, additionalServices1]);

  const handleServiceClick = (service) => {
    if (service && service.service_id) {
      navigate(`/DetailsAdd?id=${service.service_id}`);
    } else {
      console.error("Service ID is missing");
    }
  };

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className="treatment-container12" style={{ direction: getDirection() }}>
      <div className="header-section12">
        <div className="talib012">
          <div className="column12 image-column12 imagbord">
            <img src={Document} alt="Document icon" className="talib2image12" />
          </div>
          <div className="column12 text-column12">
            <p className='talib112'>
              {t("Gorfe.Headsoltitle")}
            </p>
          </div>
        </div>
      </div>

      <div className="search-bar-wrapper">
        <div className='seckrow'>
          <div className="new-input-container">
            <div className="input-group">
              <input
                type="text"
                placeholder={t("Gorfe.search_placeholder")}
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input professional-input"
                aria-label={t("Gorfe.search_placeholder")}
              />

            
            </div>

          </div>
        </div>

        

        {/* Show dropdown if search query is present and there are filtered results */}
        {searchQuery && filteredServices1.length > 0 && (
          <div className="dropdown-results">
            {filteredServices1.map(service => (
              <div
                key={service.service_id}
                className="dropdown-item"
                onClick={() => handleServiceClick(service)}
              >
                <div className="item-content">
                  <img src={service.image} alt={service.title} className="service-image" />
                  <span className="service-title">{service.title}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Show message if no results are found */}
        {searchQuery && filteredServices1.length === 0 && (
          <p>{t("services.no_services_found")}</p>
        )}
      </div>
    </div>
  );
};

export default Borde;


















