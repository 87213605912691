

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Borde from './components/Borde.js';
import './Services.css';

import { IoFilterCircleSharp } from "react-icons/io5";

import { useTranslation } from 'react-i18next';
import Slideshow from "./pages/Slideshow";
import Gorfe from './components/Gorfe.js';
import SerQuestion from './SerQuestion.js';
import ServicePage from './ServicePage.js';
import { useSpring, animated } from 'react-spring';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { Link } from "react-router-dom";
import './components/Filters.css';
import Pill from "./Pill";
 
const Services = () => {
  const { t, i18n } = useTranslation("global");
  const [openArticle, setOpenArticle] = useState(null);
  const [AdditionalServices2, setAdditionalServices2] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Controls loading state
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [serviceData, setServiceData] = useState([]); // State to store fetched service data
  const [message, setMessage] = useState("");

  const [selectedFilters, setSelectedFilters] = useState({
    pressure: false, // ضغط
    diabetes: false, // سكري
    Obesity: false, // سمنة
    male: false, // ذكر
    female: false, // أنثى
    family: false, // عائلة
    teenager: false, // ذكر
    adult: false, // أنثى
    senior: false, // عائلة
    infant: false, // عائلة
    child: false, // عائلة
    home: false,
    hospital: false,

    prevention: false, // عائلة
    diagnosis: false, // عائلة
    treatment: false,
    care: false,


    generation: false, // عائلة
    endoscope: false, // عائلة
    operation: false,
    analysis: false,
    rays: false, // عائلة
    detection: false, // عائلة
    medium: false, // متوسطة
    permanent: false, // دائمة
    oneTime: false, // مرة واحدة

    specialiste2: '',


  });
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const toggleArticle = (article) => {
    setOpenArticle(current => current === article ? null : article);
  };

  const { category } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(t('services.Obstetrics_and_Gynecology')); // Default category
  const navigate = useNavigate();
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };





  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
    }
  }, [category]);
  const handleFilterChange = (setFilter, value) => {
    setFilter(prev =>
      prev.includes(value)
        ? prev.filter(v => v !== value)
        : [...prev, value]
    );
  };
  const fetchData = () => {
    setIsLoading(true);

    const selectedMedicalProcedures = [];
    const selectedGenders = [];
    const selectedage_group = [];
    const service_location = [];
    const treatment_stage = [];
    const procedure_type = [];
    const service_duration = [];
    const cost_type = [];

    if (selectedFilters.pressure) selectedMedicalProcedures.push("ضغط");
    if (selectedFilters.diabetes) selectedMedicalProcedures.push("سكري");
    if (selectedFilters.Obesity) selectedMedicalProcedures.push("سمنة");

    if (selectedFilters.male) selectedGenders.push("ذكر");
    if (selectedFilters.female) selectedGenders.push("أنثى");
    if (selectedFilters.teenager) selectedage_group.push("مراهق");
    if (selectedFilters.adult) selectedage_group.push("بالغ");
    if (selectedFilters.senior) selectedage_group.push("كبار السن");
    if (selectedFilters.infant) selectedage_group.push("رضيع");
    if (selectedFilters.child) selectedage_group.push("طفل");
    if (selectedFilters.fetus) selectedage_group.push("جنين");


    if (selectedFilters.home) service_location.push("في المنزل");
    if (selectedFilters.hospital) service_location.push("في المستشفى");

    if (selectedFilters.prevention) treatment_stage.push("وقاية");
    if (selectedFilters.diagnosis) treatment_stage.push("تشخيص");
    if (selectedFilters.treatment) treatment_stage.push("علاج");
    if (selectedFilters.care) treatment_stage.push("رعاية");




    if (selectedFilters.generation) procedure_type.push("توليد");
    if (selectedFilters.endoscope) procedure_type.push("منظار");
    if (selectedFilters.operation) procedure_type.push("عملية");
    if (selectedFilters.analysis) procedure_type.push("تحليل");
    if (selectedFilters.rays) procedure_type.push("أشعة");
    if (selectedFilters.detection) procedure_type.push("كشف");

    if (selectedFilters.medium) service_duration.push("متوسطة");
    if (selectedFilters.permanent) service_duration.push("دائمة");
    if (selectedFilters.oneTime) service_duration.push("مرة واحدة");



    if (selectedFilters.timeCost) cost_type.push("وقت");
    if (selectedFilters.moneyCost) cost_type.push("فلوس");
    if (selectedFilters.bothCost) cost_type.push("الإثنان");
    const queryString = new URLSearchParams();

    if (selectedMedicalProcedures.length > 0) {
      queryString.append("medical_procedure", selectedMedicalProcedures.join("-"));
    }

    if (selectedGenders.length > 0) {
      queryString.append("gender", selectedGenders.join("-"));
    }

    if (selectedage_group.length > 0) {
      queryString.append("age_group", selectedage_group.join("-"));
    }
    if (service_location.length > 0) {
      queryString.append("service_location", service_location.join("-"));
    }
    if (treatment_stage.length > 0) {
      queryString.append("treatment_stage", treatment_stage.join("-"));
    }
    if (procedure_type.length > 0) {
      queryString.append("procedure_type", procedure_type.join("-"));
    }

    if (service_duration.length > 0) {
      queryString.append("service_duration", service_duration.join("-"));
    }

    if (cost_type.length > 0) {
      queryString.append("cost_type", cost_type.join("-"));
    }

    if (selectedFilters.specialiste2) {
      queryString.append("specialiste2", selectedFilters.specialiste2);
    }
    const apiUrl = `https://lahalak.sa/backend/getAdditionalServices/filtred.php?${queryString.toString()}`;

    console.log("Fetching data from: ", apiUrl);

    fetch(apiUrl, {
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched Data: ", data);

        if (Array.isArray(data)) {
          setServiceData(data);
          setMessage(""); 
        } else {
          setMessage(data.message || "No results found");
          setServiceData([]);
        }
        setIsLoading(false); 

      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setMessage("Error fetching data");
        setIsLoading(false); 

      });

    const newURL = `${window.location.pathname}?${queryString.toString()}`;
    window.history.replaceState(null, "", newURL);
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilters]);
  useEffect(() => {
    if (selectedFilters.specialiste2) {
      fetchData();
    }
  }, [selectedFilters]);

  const handleButtonClick = (filterValue) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      specialiste2: filterValue,
    }));
  };
  const handleCheckboxChange = (filter) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [filter]: !prevState[filter], 
    }));
  };

  const renderServiceData = () => {
    if (serviceData.length === 0) {
      return <p>{message || "No data available"}</p>;
    }
    return (
      <div>
      {/* Desktop view */}
      <div className="desktop-view">
        {serviceData.map((service) => (
          <div className="speed2desktop" key={service.service_id}>
            <Link to={`/DetailsAdd?id=${service.service_id}`}>
              <img className="speed4desktop" src={service.image} alt="Service Visual Representation" />
              <h5 className="speed4desktop">{service.title}</h5>
              <p className="speed5desktop">{service.summary}</p>
            </Link> 
          </div>
        ))}
      </div>
    
      {/* Mobile view */}
      <div className="mobile-view">
        {serviceData.map((service) => (
          <div className="speed2" key={service.service_id}>
            <Link to={`/DetailsAdd?id=${service.service_id}`}>
              <img className="speed3" src={service.image} alt="Service Visual Representation" />
              <h5 className="speed4">{service.title}</h5>
              <p className="speed5">{service.summary}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
    
    );
  };
  useEffect(() => {
    console.log("Loading state:", isLoading);
  }, [isLoading]);






  return (
    <div className="servicescontainer" style={{ direction: getDirection() }}>
      <div>
        <div style={{ direction: getDirection() }}>
          <div className='morese'>
            <Borde />



            <button className="filter-btn-mobile" onClick={toggleFilter}>
              <IoFilterCircleSharp />
            </button>
            <div className='contmain'>
              <div className='cont1'>
                <div className="filterdropdowndesktop" >
                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'gender' ? '' : 'gender')}>
                      {t('services.gender')}
                    </label>
                    {selectedFilter === 'gender' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.male}
                            onChange={() => handleCheckboxChange("male")}
                          />
                          ذكر (Male)
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.female}
                            onChange={() => handleCheckboxChange("female")}
                          />
                          أنثى (Female)
                        </label>

                      </div>
                    )}
                  </div>

                  {/* Age Category Filter */}
                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'ageCategory' ? '' : 'ageCategory')}>
                      {t('services.age_category')}
                    </label>
                    {selectedFilter === 'ageCategory' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.infant}
                            onChange={() => handleCheckboxChange("infant")}
                          />
                          رضيع (infant)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.senior}
                            onChange={() => handleCheckboxChange("senior")}
                          />
                          طفل (senior)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.teenager}
                            onChange={() => handleCheckboxChange("teenager")}
                          />
                          مراهق
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.adult}
                            onChange={() => handleCheckboxChange("adult")}
                          />
                          بالغ 
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.child}
                            onChange={() => handleCheckboxChange("child")}
                          />
                          كبار السن
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.fetus}
                            onChange={() => handleCheckboxChange("fetus")}
                          />
                          جنين
                        </label>
                      </div>
                    )}
                  </div>

                  {/* Treatment Phase Filter */}
                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'treatmentPhase' ? '' : 'treatmentPhase')}>
                      {t('services.treatment_phase')}
                    </label>
                    {selectedFilter === 'treatmentPhase' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.prevention}
                            onChange={() => handleCheckboxChange("prevention")}
                          />
                          وقاية
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.diagnosis}
                            onChange={() => handleCheckboxChange("diagnosis")}
                          />
                          تشخيص
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.treatment}
                            onChange={() => handleCheckboxChange("treatment")}
                          />
                          علاج
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.care}
                            onChange={() => handleCheckboxChange("care")}
                          />
                          رعاية
                        </label>
                      </div>
                    )}
                  </div>

                  {/* Medical Procedures Filter */}
                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'medicalProcedure' ? '' : 'medicalProcedure')}>
                      {t('services.medical_procedure')}
                    </label>
                    {selectedFilter === 'medicalProcedure' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.endoscope}
                            onChange={() => handleCheckboxChange("endoscope")}
                          />
                          منظار (endoscope)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.operation}
                            onChange={() => handleCheckboxChange("operation")}
                          />
                          عملية (operation)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.generation}
                            onChange={() => handleCheckboxChange("generation")}
                          />
                          توليد (generation)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.analysis}
                            onChange={() => handleCheckboxChange("analysis")}
                          />
                          تحليل (analysis)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.rays}
                            onChange={() => handleCheckboxChange("rays")}
                          />
                          أشعة (rays)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.detection}
                            onChange={() => handleCheckboxChange("detection")}
                          />
                          كشف (detection)
                        </label>
                      </div>
                    )}
                  </div>


                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'condition' ? '' : 'condition')}>
                      {t('services.condition')}
                    </label>
                    {selectedFilter === 'condition' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.pressure}
                            onChange={() => handleCheckboxChange("pressure")}
                          />
                          ضغط (Blood Pressure)
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.diabetes}
                            onChange={() => handleCheckboxChange("diabetes")}
                          />
                          سكري
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.Obesity}
                            onChange={() => handleCheckboxChange("Obesity")}
                          />
                       بدانة
                        </label>

                      </div>
                    )}
                  </div>

                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'serviceDuration' ? '' : 'serviceDuration')}>
                      {t('services.service_duration')}
                    </label>
                    {selectedFilter === 'serviceDuration' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.medium}
                            onChange={() => handleCheckboxChange("medium")}
                          />
                          متوسطة
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.permanent}
                            onChange={() => handleCheckboxChange("permanent")}
                          />
                          دائمة
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.oneTime}
                            onChange={() => handleCheckboxChange("oneTime")}
                          />
                          مرة واحدة
                        </label>

                      </div>
                    )}
                  </div>

                  {/* Service Location Filter */}
                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'serviceLocation' ? '' : 'serviceLocation')}>
                      {t('services.service_location')}
                    </label>
                    {selectedFilter === 'serviceLocation' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.hospital}
                            onChange={() => handleCheckboxChange("hospital")}
                          />
                          في المستشفى
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.home}
                            onChange={() => handleCheckboxChange("home")}
                          />
                          في المنزل
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="filter-row">
                    <label onClick={() => setSelectedFilter(selectedFilter === 'savingsType' ? '' : 'savingsType')}>
                      {t('services.savings_type')}
                    </label>
                    {selectedFilter === 'savingsType' && (
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.moneyCost}
                            onChange={() => handleCheckboxChange("moneyCost")}
                          />
                          فلوس
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.bothCost}
                            onChange={() => handleCheckboxChange("bothCost")}
                          />
                          الإثنان
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedFilters.timeCost}
                            onChange={() => handleCheckboxChange("timeCost")}
                          />
                          وقت (Time)
                        </label>
                      </div>
                    )}
                  </div>

                </div>

              </div>
              <div className='cont2'>

                <div>
                  <button className="Button" onClick={() => handleButtonClick("أمراض-الكبد")}>
                    أمراض-الكبد
                  </button>
                  <button onClick={() => handleButtonClick("أمراض-الكلى")}>
                    أمراض-الكلى
                  </button>

                </div>
                <div>
                  <br />
                  <h1 className="TitlContw">{t('services.Children').replace(/-/g, ' ')}</h1>
                  <div className="speed1">
                    {isLoading ? (
                      <div>
                        <Pill />
                        {console.log("Currently loading data...")}
                      </div>
                    ) : (
                      renderServiceData()
                    )}
                  </div>
                </div>


              </div>
            </div>
            <div className="mobile-filter">


              <div className={`filter-dropdown-mobile ${isFilterVisible ? 'visible' : ''}`}>
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'gender' ? '' : 'gender')}>
                    {t('services.gender')}
                  </label>
                  {selectedFilter === 'gender' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.male}
                          onChange={() => handleCheckboxChange("male")}
                        />
                        ذكر (Male)
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.female}
                          onChange={() => handleCheckboxChange("female")}
                        />
                        أنثى (Female)
                      </label>

                    </div>
                  )}
                </div>

                {/* Mobile Age Category Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'age_category' ? '' : 'age_category')}>
                    {t('services.age_category')}
                  </label>
                  {selectedFilter === 'age_category' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.infant}
                          onChange={() => handleCheckboxChange("infant")}
                        />
                        رضيع (infant)
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.senior}
                          onChange={() => handleCheckboxChange("senior")}
                        />
                        طفل (senior)
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.teenager}
                          onChange={() => handleCheckboxChange("teenager")}
                        />
                        مراهق (teenager)
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.adult}
                          onChange={() => handleCheckboxChange("adult")}
                        />
                        بالغ (adult)
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.child}
                          onChange={() => handleCheckboxChange("child")}
                        />
                        كبار السن (child)
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.fetus}
                          onChange={() => handleCheckboxChange("fetus")}
                        />
                        جنين (fetus)
                      </label>
                    </div>

                  )}
                </div>

                {/* Mobile Treatment Phase Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'treatmentPhase' ? '' : 'treatmentPhase')}>
                    {t('services.treatment_phase')}
                  </label>
                  {selectedFilter === 'treatmentPhase' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.prevention}
                          onChange={() => handleCheckboxChange("prevention")}
                        />
                        وقاية
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.diagnosis}
                          onChange={() => handleCheckboxChange("diagnosis")}
                        />
                        تشخيص
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.treatment}
                          onChange={() => handleCheckboxChange("treatment")}
                        />
                        علاج
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.care}
                          onChange={() => handleCheckboxChange("care")}
                        />
                        رعاية
                      </label>
                    </div>
                  )}
                </div>

                {/* Mobile Medical Procedures Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'medicalProcedure' ? '' : 'medicalProcedure')}>
                    {t('services.medical_procedure')}
                  </label>
                  {selectedFilter === 'medicalProcedure' && (
                    <div className="checkbox-group">



                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.endoscope}
                          onChange={() => handleCheckboxChange("endoscope")}
                        />
                        منظار
                      </label>


                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.operation}
                          onChange={() => handleCheckboxChange("operation")}
                        />
                        عملية
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.generation}
                          onChange={() => handleCheckboxChange("generation")}
                        />
                        توليد
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.analysis}
                          onChange={() => handleCheckboxChange("analysis")}
                        />
                        تحليل
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.rays}
                          onChange={() => handleCheckboxChange("rays")}
                        />
                        أشعة
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.detection}
                          onChange={() => handleCheckboxChange("detection")}
                        />
                        كشف
                      </label>
                    </div>
                  )}
                </div>


                {/* Mobile Condition Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'condition' ? '' : 'condition')}>
                    {t('services.condition')}
                  </label>
                  {selectedFilter === 'condition' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.pressure}
                          onChange={() => handleCheckboxChange("pressure")}
                        />
                        ضغط
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.diabetes}
                          onChange={() => handleCheckboxChange("diabetes")}
                        />
                        سكري
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.Obesity}
                          onChange={() => handleCheckboxChange("Obesity")}
                        />
                        سمنة
                      </label>
                    </div>
                  )}
                </div>

                {/* Mobile Service Duration Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'serviceDuration' ? '' : 'serviceDuration')}>
                    {t('services.service_duration')}
                  </label>
                  {selectedFilter === 'serviceDuration' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.medium}
                          onChange={() => handleCheckboxChange("medium")}
                        />
                        متوسطة
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.permanent}
                          onChange={() => handleCheckboxChange("permanent")}
                        />
                        دائمة
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.oneTime}
                          onChange={() => handleCheckboxChange("oneTime")}
                        />
                        مرة واحدة
                      </label>
                    </div>
                  )}
                </div>

                {/* Mobile Service Location Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'serviceLocation' ? '' : 'serviceLocation')}>
                    {t('services.service_location')}
                  </label>
                  {selectedFilter === 'serviceLocation' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.hospital}
                          onChange={() => handleCheckboxChange("hospital")}
                        />
                        في المستشفى
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.home}
                          onChange={() => handleCheckboxChange("home")}
                        />
                        في المنزل
                      </label>
                    </div>
                  )}
                </div>

                {/* Mobile Savings Type Filter */}
                <div className="filter-row">
                  <label className="filter-title" onClick={() => setSelectedFilter(selectedFilter === 'savingsType' ? '' : 'savingsType')}>
                    {t('services.savings_type')}
                  </label>
                  {selectedFilter === 'savingsType' && (
                    <div className="checkbox-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.moneyCost}
                          onChange={() => handleCheckboxChange("moneyCost")}
                        />
                        فلوس
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.bothCost}
                          onChange={() => handleCheckboxChange("bothCost")}
                        />
                        الإثنان
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedFilters.timeCost}
                          onChange={() => handleCheckboxChange("timeCost")}
                        />
                        وقت
                      </label>
                    </div>
                  )}
                </div>

                {/* Close filter dropdown button */}
                <button className="close-filter-btn" onClick={toggleFilter}>
                  {t('services.close')}
                </button>
              </div>
            </div>




          </div>
        </div>

        {/* Additional Components */}
        <Slideshow />
        <Gorfe />
        <SerQuestion />


      </div>
    </div>
  );
};

export default Services;




